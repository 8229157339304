html, body {
    overflow-x: hidden;
  }
  
  * {
    font-family: 'Roboto', sans-serif;
  }
  
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', sans-serif;
    line-height: 150%;
  }
  
  .hero-head {
    font-size: 32px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    line-height: 150%;
  }
  
  .hero-head span {
    font-size: 1.2em;
  }
  
  .responsive-image {
    width: 100%;
    height: auto;
  }
  .p1 {
    padding: 10px;
  }
  
  .p2 {
    padding: 20px;
  }
  
  .p3 {
    padding: 30px;
  }
  
  .px1 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .px2 {
    padding-top: 30px;
    padding-bottom: 50px;
  }
  
  .pr3 {
    padding-right: 30px;
  }
  
  .hero-block {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  
  p{
    line-height: 200%;
  }
  
  .modal-form {
    width: 80vw;
    margin: 5vh auto;
    padding: 5vh 5vw;
    background-color: #ffffff;
  }
  
  .form {
    width: 100%;
  }
  
  .btn-text {
    text-transform: inherit;
  }
  
  .textfield {
    width: 100%;
  }
  
  .swiper-button-prev, .swiper-button-next {
    margin-top: -80px !important;
  }