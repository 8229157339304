body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #222222;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #222222;
}

a {
  text-decoration: none;
}

.main {
  background: url('https://ik.imagekit.io/torit/background_rG8AoXYUJ.svg') no-repeat center top;
  background-size: cover;
}

.bg-blue {
  background-color: #0099e7;
}

.blue {
  color: #0099e7;
}

.divison {
  padding: 50px 0px
}

.paper {
  padding: 20px 0 50px 0;
}

Button.nav-item, .nav-item {
  color: #ffffff;
  text-transform: inherit;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  text-decoration: none;
  margin: 0px 7px;
}

.responsive-img {
  width: 100%;
  height: auto;
}

.credit {
  font-size: 0.8em;
  color: #333333;
}

.nav-img {
  height: 50px;
  width: auto;
}

.drawer {
  width: 70vw;
}


.heading {
  text-align: center;
  color: #222222;
  padding: 20px 0px;
  font-size: 40px;
}

.blog {
  background: url('https://ik.imagekit.io/torit/back_9qE-dY7h8.svg') no-repeat Right bottom;
  background-size: cover;
}

.blogpage {
  font-size: 1.3em;
  text-align: justify;
}

.service-item {
  text-align: center;
  border-radius: 2;
  max-width: 100%;
  padding: 25px;
  margin: 15px;
  font-size: 1.2em;
}

.service-img {
  height: 100px;
  width: auto;
  margin: 20px auto 0;
}

.product-img {
  width: 50%;
  height: auto;
}

.team-img {
  width: 150px;
  height: 150px;
}

.tech-img {
  width: 90%;
  height: auto;
  padding: 10px 5%;
}

.philosophy-img {
  width: 80%;
  margin: 10px 10%;
}

.philosophy-text {
  font-size: 1.5em;
  line-height: 150%;
}

.testimonial-item {
  width: 100%;
  text-align: center;
}

.blog-text {
  font-size: 1.1em;
  line-height: 140%;
}

.textfield {
  border-radius: 8px;
  background: #ffffff;
  width: 100%;
  margin: 10px 0px;
}

.mt30 {
  margin: 30px 0px;
}


.triangle {
  position: relative;
  margin: 10px;
  padding: 10px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 3px 3px 1px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 100%;
  min-height: 250px;
}
.triangle::after{
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    bottom: -20px;
    left: 45%;
    box-sizing: border-box;
    
    border: 10px solid black;
    border-color: transparent transparent #ffffff #ffffff;
    
    transform-origin: 0 0;
    transform: rotate(-45deg);
    
    box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.4);
  }

  .swiper-button-prev, .swiper-button-next {
    height: 30px !important;
    width: 30px !important;
  }

  .swiper-button-prev {
    left: 0 !important;
  }

  .swiper-button-next {
    right: 0 !important;
  }

  @media only screen and (max-width: 768px) {
    .swiper-button-prev, .swiper-button-next {
      display: none !important;
    }
  }

  .modal-form {
    width: 80vw;
    margin: 5vh auto;
    padding: 5vh 5vw;
    background-color: #ffffff;
    height: auto;
  }

  .form {
    width: 100%;
  }
  
  .btn-text {
    text-transform: inherit;
  }
  
  .textfield {
    width: 100%;
  }